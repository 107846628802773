
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/epidemic";

@Options({})
export default class extends Vue {
  private healthCodeUrl = "";
  private trackUrl = "";
  private temperatuerList: any[] = [];
  private refreshing = false;
  private loading = false;
  private finished = false;
  private username = "";

  protected goBack() {
    this.$store.commit("project/set_Active", 2);
    this.$router.push("/app/epidemic");
  }

  mounted() {
    this.username = this.$route.query.name as any;
    api
      .userTrackRecordGetByUserId({
        projectId: this.$store.state.project.projectinfo.projectId,
        userId: this.$route.query.id,
      })
      .then((res: any) => {
        if (res.data != null) {
          this.healthCodeUrl = res.data.healthCodeUrl;
          this.trackUrl = res.data.trackUrl;
        }
      });
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.temperatuerList = [];
        this.refreshing = false;
      }
      api
        .userTemperatureRecordGetListByUser({
          pageNum: parseInt(this.temperatuerList.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfo.projectId,
          userId: this.$route.query.id,
        })
        .then((res: any) => {
          this.temperatuerList = [...this.temperatuerList, ...res.data.records];
          this.loading = false;
          if (this.temperatuerList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
